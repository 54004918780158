import {Link} from "react-router-dom";
import {useContext, useState} from "react";
import AppContext from "../../../../context";
import NavItem from "./NavItem";
import {Calendar, Grid, LogOut, Menu, Settings, ShoppingCart, X} from "react-feather";
import classNames from "classnames";

const Sidebar = () => {
    const context = useContext(AppContext);
    const [open, setOpen] = useState(false);

    return (
        <div className={"z-10 fixed bg-white h-auto lg:h-dvh w-full lg:w-72 shrink-0 lg:border-r border-b p-4 flex flex-col justify-between"}>
            <div className={"flex flex-col gap-4 lg:gap-8 items-start justify-start"}>
                <div className={"flex items-center justify-between w-full"}>
                    <Link to={'/'} className={"flex items-center gap-2"}>
                        <div className={'block w-10 h-10 rounded-full bg-primary'}>
                            <img src={context.domain.logo} alt={context.domain.name} className={"w-full"}/>
                        </div>
                        <p>{context.domain.name}</p>
                    </Link>
                    <div className={"relative lg:hidden cursor-pointer"} onClick={() => setOpen(!open)}>
                        {open ? <X  /> : <Menu  />}
                    </div>
                </div>

                <nav className={classNames("flex-col gap-2 w-full lg:flex", open ? "flex" : "hidden")} onClick={() => setOpen(false)}>
                    <NavItem to={"/admin/upcoming-events"} label={"Upcoming Events"} icon={<Calendar size={20} />} />
                    <NavItem to={"/admin/bookings"} label={"Bookings"} icon={<ShoppingCart size={20} />} />
                    <NavItem to={"/admin/experiences"} label={"Experiences"} icon={<Grid size={20} />} />
                    <NavItem to={"/admin/site-settings"} label={"Site Settings"} icon={<Settings size={20} />} />
                </nav>
            </div>

            <div className={classNames("mt-2 lg:mt-0 lg:flex", open ? "flex" : "hidden")}>
                <NavItem to={"/log-out"} label={"Log Out"} icon={<LogOut size={20} />} />
            </div>
        </div>
    )
}

export default Sidebar