import {useEffect, useMemo, useRef, useState} from 'react';
import { Capacitor } from "@capacitor/core";

import StudioSelector from "./StudioSelector";
import WeekSelector from "./WeekSelector";
import Sessions from "./Sessions";
import Header from "../../../components/Header";

import styles from '../../../../styles/Calendar.module.scss';

function Calendar({ studio, setStudio, week, setWeek, studios }) {

	const [loading, setLoading] = useState(true);

	const start = useMemo(() => {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		const monday = today.getDate() - ((today.getDay() + 6) % 7);
		return new Date(today.setDate(monday + (7 * week)));
	}, [week]);
	const end = useMemo(() => {
		const endDate = new Date(start);
		endDate.setDate(endDate.getDate() + 7);
		return endDate;
	}, [start]);
	
	return (
		<>
			{Capacitor.isNativePlatform() && <Header heading={"Create Booking"} />}
			<div className={styles.calendar}>
				<div className={styles.controls}>
					<StudioSelector studio={studio} setStudio={setStudio} studios={studios} loading={loading} setLoading={setLoading} />
					<WeekSelector week={week} setWeek={setWeek} loading={loading} setLoading={setLoading} />
				</div>
				<Sessions studio={studio} start={start} end={end} loading={loading} setLoading={setLoading} />
			</div>
		</>
	)
}

export default Calendar;