import Header from "../components/Header";
import {Edit3, ExternalLink} from "react-feather";
import {NavLink, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Data from "../../Data";
import Error from "../components/Error";
import ExperienceEvents from "../components/ExperienceEvents";
import Meta from "../../../components/Meta";

const Experience = () => {
    const { id } = useParams();

    const [experience, setExperience] = useState({});
    const [loading, setLoading] = useState(true);

    const [deleteError, setDeleteError] = useState(false);

    useEffect(async () => {
        try {
            const experience = await Data.fetchExperienceById({ id })
            setExperience(experience.data)
        } catch(error){
            window.location.href = '/admin/experiences'
        } finally {
            setLoading(false)
        }
    }, []);

    const deleteExperience = async () => {
        try {
            if(window.confirm(`Are you sure you want to delete ${experience.name}?`)){
                await Data.deleteExperience({ id })
                window.location.href = '/admin/experiences'
            }
        } catch(error){
            setDeleteError(true)
        }
    }

    return (
        <>
            <Meta title={experience.name} />
            <Header heading={experience.name} backToLabel={"Experiences"} backToTo={"/admin/experiences"} />
            <div className={"p-3 lg:p-6 grid grid-cols-1 gap-3 lg:gap-6 w-full overflow-x-scroll"}>

                {/*<div className={"bg-red-200 text-white w-full rounded-lg drop-shadow-sm [&>*]:py-4 [&>*]:px-6"}>*/}
                {/*    <div className={"flex items-center gap-2 flex-wrap"}>*/}
                {/*        <EyeOff size={20} className={"text-red-900"}/>*/}
                {/*        <p className={"text-red-900"}>This experience has been automatically hidden from public view until it's price and refund window have been set.</p>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className={"bg-white w-full rounded-lg border drop-shadow-sm [&>*]:py-4 [&>*]:px-6"}>
                    <div className={"my-2 flex justify-between items-center"}>
                        <p className={"text-lg"}>Experience Details</p>
                        <NavLink to={`/admin/experiences/${id}/edit`}
                                 className={"bg-backgroundGrey border flex items-center gap-1 px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}><Edit3
                            size={16}/> Edit</NavLink>

                    </div>

                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Name</p>
                        <p className={"text-textGrey col-span-2"}>{experience.name}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Status</p>
                        <p className={"text-textGrey col-span-2"}>{experience.isPublished ? "Published" : "Unpublished"}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>URL</p>
                        <a href={`${process.env.REACT_APP_BASE_URL}/${experience.slug}`} target={"_blank"}
                           className={"text-textGrey col-span-2 underline flex items-center gap-1"}>{process.env.REACT_APP_BASE_URL}/experiences/{experience.slug}
                            <ExternalLink
                                size={16}/></a>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Short Description</p>
                        <p className={"text-textGrey col-span-2"}>{experience.shortDescription}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Description</p>
                        <p className={"text-textGrey col-span-2"}>{experience.description}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Additional Details</p>
                        <p className={"text-textGrey col-span-2"}>{experience.additionalDetails}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Cancellation Policy</p>
                        <p className={"text-textGrey col-span-2"}>{experience.cancellationPolicy}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Location</p>
                        <p className={"text-textGrey col-span-2"}>{experience.location}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Duration</p>
                        <p className={"text-textGrey col-span-2"}>{experience.duration} minutes</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Price</p>
                        <p className={"text-textGrey col-span-2"}>&pound;{experience.price} per ticket</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Refund Window</p>
                        <p className={"text-textGrey col-span-2"}>{experience.refundWindow} hours</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Minimum People</p>
                        <p className={"text-textGrey col-span-2"}>{experience.minPeople}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Maximum People</p>
                        <p className={"text-textGrey col-span-2"}>{experience.maxPeople}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Image</p>
                        <div className={"w-96 max-w-full bg-backgroundGrey rounded-md overflow-hidden"}>
                            {experience.image ? <img src={experience.image} className={"w-full"} /> : null}
                        </div>
                    </div>
                </div>

                <ExperienceEvents/>

                <div className={"bg-white w-full rounded-lg border drop-shadow-sm [&>*]:py-4 [&>*]:px-6"}>
                    <div className={"my-2"}>
                        <p className={"text-lg"}>Delete {experience.name}</p>
                        <p className={"text-textGrey"}>Deleting this experience will permanently remove it. Any existing
                            bookings <span className={"text-black"}>will not</span> be automatically cancelled.</p>
                    </div>

                    {deleteError ?
                        <Error message={"An error occurred while deleting this experience. Please try again."}/> : null}

                    <div className={"mt-2 flex gap-2 border-t"}>
                        <button onClick={deleteExperience}
                                className={"bg-red-200 text-red-900 border-0 px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>Permanently
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Experience