import Form from "./Form";
import Data from "../../Data";
import {useEffect, useState} from "react";
import Error from "./Error";
import {useParams} from "react-router-dom";

const ExperienceImageUpload = () => {
    const { id } = useParams();

    const [file, setFile] = useState(null);
    const [experience, setExperience] = useState({})
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(async () => {
        const experience = await Data.fetchExperienceById({id})
        setExperience(experience.data)
    }, [])

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await Data.uploadImage({ formData })
            await Data.updateExperienceImage({ id, image: response.data.data.Location })
            window.location.href = '/admin/experiences/' + id;
        } catch (error) {
            setError(true)
        } finally {
            setLoading(false)
            setFile(null)
        }
    };

    return (
        <Form heading={"Image"} onSubmit={handleSubmit}>

            <div className={"w-96 bg-backgroundGrey rounded-md overflow-hidden"}>
                {experience.image ? <img src={experience.image} className={"w-full"} /> : null}
            </div>

            <div className={"flex items-center gap-2 flex-wrap"}>
                <input type="file" disabled={loading} onChange={handleFileChange}/>
                <button type={"submit"} disabled={loading}
                        className={"bg-backgroundGrey border px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>{loading ? "Uploading..." : "Upload"}</button>
            </div>

            {error ? <Error message={"An error occurred with your image, please try again."}/> : null}
        </Form>
    )
}

export default ExperienceImageUpload;