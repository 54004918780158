import Header from "../components/Header";
import {NavLink} from "react-router-dom";
import {Download, Plus} from "react-feather";
import Empty from "../components/Empty";
import {useEffect, useState} from "react";
import Data from "../../Data";
import moment from "moment/moment";
import Meta from "../../../components/Meta";

const UpcomingEvents = () => {

    const [events, setEvents] = useState([]);

    useEffect(async () => {
        const events = await Data.fetchAllEvents()
        setEvents(events.data)
    }, []);

    return (
        <>
            <Meta title={"Upcoming Events"} />
            <Header heading={"Upcoming Events"} />
            <div className={"p-3 lg:p-6 grid grid-cols-1 gap-3 lg:gap-6 w-full overflow-x-scroll"}>
                {events.length === 0 ?
                    <Empty>
                        <p>No Upcoming Events</p>
                    </Empty>
                    :

                    <table
                        className={"w-full border-collapse outline outline-1 outline-gray-200 rounded-lg overflow-hidden drop-shadow-sm"}>
                        <thead className={"bg-gray-50"}>
                        <tr>
                            <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Date &amp; Time</th>
                            <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Experience</th>
                            <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Tickets</th>
                            <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Status</th>
                            <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Actions</th>
                        </tr>
                        </thead>
                        <tbody className={"bg-white"}>
                        {events.map((event, index) => (
                            <tr className={"border-t"}>
                                <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>{moment(event.date).format('MMM Do YYYY, H:mm A')}</td>
                                <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}><NavLink to={`/admin/experiences/${event.Experience._id}`}
                                                                     className={"underline"}>{event.Experience.name}</NavLink>
                                </td>
                                <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>{event.totalTickets === 0 ? '-' : `${event.totalTickets} ticket${event.totalTickets > 1 ? 's' : ''} (${event.bookings.length} booking${event.bookings.length > 1 ? 's' : ''})`}</td>
                                <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>{event.ticketsRemaining > 0 ? `${event.ticketsRemaining} tickets remaining` : 'Sold Out'}</td>
                                <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>
                                    <div className={"flex gap-2"}>
                                        <NavLink to={`/admin/upcoming-events/${event._id}`}
                                                 className={"bg-backgroundGrey px-2.5 py-1 rounded hover:opacity-80 transition"}>View</NavLink>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>}
                {/*<div className={"flex justify-center"}>*/}
                {/*    <NavLink to={""} className={"bg-gray-200 px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>Load More</NavLink>*/}
                {/*</div>*/}
            </div>
        </>
    )
}

export default UpcomingEvents