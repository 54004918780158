import Header from "../components/Header";
import {NavLink} from "react-router-dom";
import Empty from "../components/Empty";
import {useEffect, useState} from "react";
import Data from "../../Data";
import Error from "../components/Error";
import moment from "moment";
import Meta from "../../../components/Meta";

const Bookings = () => {

    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(async () => {
        try {
            const bookings = await Data.fetchAllExperienceBookings()
            setBookings(bookings.data)
        } catch(error){
            setError(true)
        } finally {
            setLoading(false)
        }
    }, []);

    return (
        <>
            <Meta title={"Bookings"} />
            <Header heading={"Bookings"} />
            <div className={"p-3 lg:p-6 grid grid-cols-1 gap-3 lg:gap-6 w-full overflow-x-scroll"}>

                {error ? <Error message={"Unable to load experiences, please refresh the page."}/> : null}

                {loading ?
                    <Empty>
                        <p>Loading...</p>
                    </Empty>
                    : bookings.length === 0 ?
                        <Empty>
                            <p>No Bookings</p>
                        </Empty>
                        :

                        <table
                            className={"w-full border-collapse outline outline-1 outline-gray-200 rounded-lg overflow-hidden drop-shadow-sm"}>
                            <thead className={"bg-gray-50"}>
                            <tr>
                                <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Reference</th>
                                <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Name</th>
                                <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Experience</th>
                                <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Date</th>
                                <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Tickets</th>
                                <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Booked On</th>
                                <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Actions</th>
                            </tr>
                            </thead>
                            <tbody className={"bg-white"}>
                            {bookings.map((booking, index) => (
                                <tr className={"border-t"}>
                                    <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>{booking.reference}</td>
                                    <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>{booking.User.firstName} {booking.User.lastName}</td>
                                    <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>{booking.Event.Experience.name}</td>
                                    <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>{moment(booking.Event.date).format("MMM Do YYYY, H:mm A")}</td>
                                    <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>{booking.tickets} people</td>
                                    <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>{moment(booking.bookedOn).format("MMM Do YYYY, H:mm A")}</td>
                                    <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>
                                        <div className={"flex gap-2"}>
                                            <NavLink to={`/admin/bookings/${booking._id}`}
                                                     className={"bg-backgroundGrey px-2.5 py-1 rounded hover:opacity-80 transition"}>View</NavLink>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                }
            </div>
        </>
    )
}

export default Bookings