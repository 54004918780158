import {NavLink, useParams} from "react-router-dom";
import Empty from "./Empty";
import {useEffect, useState} from "react";
import Data from "../../Data";
import moment from "moment/moment";

const EventBookings = () => {
    const { id } = useParams();

    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(async () => {
        try {
            const bookings = await Data.fetchExperienceBookingsByEventId({ id })
            setBookings(bookings.data)
        } catch(error){
            setError(true)
        } finally {
            setLoading(false)
        }
    }, []);

    if(bookings.length === 0) return (
        <Empty>
            <p>No Bookings</p>
        </Empty>
    )

    return (
        <>
            <div
                className={"w-full border-collapse outline outline-1 outline-gray-200 rounded-lg overflow-x-scroll drop-shadow-sm"}>
                <div className={"bg-white p-6 flex items-center justify-between border-b"}>
                    <p className={"text-lg"}>Bookings</p>
                </div>

                <table className={"w-full"}>
                    <thead className={"bg-gray-50"}>
                    <tr>
                        <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Reference</th>
                        <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Name</th>
                        <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Tickets</th>
                        <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Booked On</th>
                        <th className={"text-left px-4 py-3 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>Actions</th>
                    </tr>
                    </thead>
                    <tbody className={"bg-white"}>
                    {bookings.map(booking => (
                        <tr className={"border-t"}>
                            <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>{booking.reference}</td>
                            <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>{booking.User.firstName} {booking.User.lastName}</td>
                            <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>{booking.tickets} tickets</td>
                            <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>{moment(booking.bookedOn).format("MMM Do YYYY, H:mm A")}</td>
                            <td className={"px-4 py-2 lg:px-6 lg:py-4 truncate overflow-ellipsis"}>
                                <div className={"flex gap-2"}>
                                    <NavLink to={`/admin/bookings/${booking._id}`}
                                             className={"bg-backgroundGrey px-2.5 py-1 rounded hover:opacity-80 transition"}>View</NavLink>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

        </>
    )
}

export default EventBookings