import Form from "./Form";
import Data from "../../Data";
import {useContext, useEffect, useState} from "react";
import Error from "./Error";
import {useParams} from "react-router-dom";
import AppContext from "../../../../context";

const SiteSettingsImageUpload = () => {
    const context = useContext(AppContext);

    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await Data.uploadImage({ formData })
            await Data.updateSiteSettings({ name: context.domain.name, primaryColor: context.domain.primaryColor, heading: context.domain.heading, subHeading: context.domain.subHeading, backgroundImage: response.data.data.Location, logo: context.domain.logo, favicon: context.domain.favicon })
            context.fetchDomain()
        } catch (error) {
            setError(true)
        } finally {
            setLoading(false)
            setFile(null)
        }
    };

    return (
        <Form heading={"Background Image"} onSubmit={handleSubmit}>

            <div className={"w-96 bg-backgroundGrey rounded-md overflow-hidden"}>
                {context.domain.backgroundImage ? <img src={context.domain.backgroundImage} className={"w-full"} /> : null}
            </div>

            <div className={"flex items-center gap-2 flex-wrap"}>
                <input type="file" disabled={loading} onChange={handleFileChange}/>
                <button type={"submit"} disabled={loading}
                        className={"bg-backgroundGrey border px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>{loading ? "Uploading..." : "Upload"}</button>
            </div>

            {error ? <Error message={"An error occurred with your image, please try again."}/> : null}
        </Form>
    )
}

export default SiteSettingsImageUpload;