const Form = ({ children, heading, onSubmit }) => {
    return (
        <div className={"bg-white w-full p-4 lg:p-6 rounded-lg border drop-shadow-sm"}>
            {heading ? <p className={"text-lg mb-4"}>{heading}</p> : null}

            <form className={"grid grid-cols-1 gap-4"} onSubmit={onSubmit}>
                {children}
            </form>
        </div>
    )
}

export default Form