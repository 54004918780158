import Sidebar from "./components/Sidebar";
import {Outlet} from "react-router-dom";

const Layout = () => {
    return(
        <div className={"bg-white [&_*]:font-sans flex items-start flex-col lg:flex-row"}>
            <Sidebar />
            <main className={"w-full min-h-dvh bg-backgroundGrey mt-[73px] lg:mt-0 lg:ml-72"}>
                <Outlet />
            </main>
        </div>
    )
}

export default Layout