import Header from "../components/Header";
import {Delete, Download, Edit2, Edit3, Plus, Trash, Trash2} from "react-feather";
import {NavLink, useParams} from "react-router-dom";
import InputWrapper from "../../components/InputWrapper";
import Form from "../components/Form";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import Error from "../components/Error";
import Data from "../../Data";
import Meta from "../../../components/Meta";
import One from "./addEvents/One";
import Range from "./addEvents/Range";
import Weekly from "./addEvents/Weekly";

const AddEvents = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [experience, setExperience] = useState({});

    const [deleteError, setDeleteError] = useState(false);

    useEffect(async () => {
        try {
            const experience = await Data.fetchExperienceById({ id })
            setExperience(experience.data)
        } catch(error){
            window.location.href = '/admin/experiences'
        }
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm()

    const submit = async (data) => {
        try {
            setLoading(true)
            await Data.createEvents({ experienceId: id, dates: [data.date] })
            setLoading(false)
            setError(false)
        } catch(error) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Meta title={`Add Events to ${experience.name}`} />
            <Header heading={"Add Events"} backToLabel={experience.name} backToTo={`/admin/experiences/${experience._id}`} />
            <div className={"p-3 lg:p-6 grid grid-cols-1 gap-3 lg:gap-6 w-full overflow-x-scroll"}>

                <One/>

                <Range/>

                <Weekly/>

                {/*<Form heading={"One At A Time"} onSubmit={handleSubmit(submit)}>*/}

                {/*    <InputWrapper label={"Date"} input={<div className={"flex items-center gap-2"}>*/}
                {/*        <input type={"datetime-local"} className={"w-full"} {...register('date', { required: true })} />*/}
                {/*    </div>} error={errors.date ? "Date is required" : null}/>*/}

                {/*    /!*<InputWrapper label={"Times"} input={<input type={"text"}/>}/>*!/*/}

                {/*    <div className={"mt-2 flex gap-2"}>*/}
                {/*        <button type={"submit"} disabled={loading}*/}
                {/*                 className={"bg-backgroundGrey border px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>{loading ? "Loading..." : "Create Event"}</button>*/}

                {/*    </div>*/}

                {/*    {error ? <Error message={"An error occurred, please try again"} /> : null}*/}
                {/*</Form>*/}

                {/*<div*/}
                {/*    className={"w-full border-collapse outline outline-1 outline-gray-200 rounded-lg overflow-hidden drop-shadow-sm"}>*/}
                {/*    <div className={"bg-white p-6 flex items-center justify-between border-b"}>*/}
                {/*        <p className={"text-lg"}>Recently Added</p>*/}
                {/*    </div>*/}

                {/*    <table className={"w-full"}>*/}
                {/*        <thead className={"bg-gray-50"}>*/}
                {/*            <tr>*/}
                {/*                <th className={"text-left px-6 py-4"}>Date &amp; Time</th>*/}
                {/*                <th className={"text-left px-6 py-4"}>Actions</th>*/}
                {/*            </tr>*/}
                {/*        </thead>*/}
                {/*        <tbody className={"bg-white"}>*/}
                {/*        <tr className={"border-t"}>*/}
                {/*            <td className={"px-6 py-4"}>Today at 10:00 am</td>*/}
                {/*            <td className={"px-6 py-2"}>*/}
                {/*                <div className={"flex gap-2"}>*/}
                {/*                    <NavLink to={""}*/}
                {/*                             className={"bg-backgroundGrey px-2.5 py-1 flex items-center gap-1 rounded hover:opacity-80 transition"}><Trash2*/}
                {/*                        size={16}/> Delete</NavLink>*/}
                {/*                </div>*/}
                {/*            </td>*/}
                {/*        </tr>*/}
                {/*        <tr className={"border-t"}>*/}
                {/*            <td className={"px-6 py-4"}>Today at 14:00 am</td>*/}
                {/*            <td className={"px-6 py-2"}>*/}
                {/*                <div className={"flex gap-2"}>*/}
                {/*                    <NavLink to={""}*/}
                {/*                             className={"bg-backgroundGrey px-2.5 py-1 flex items-center gap-1 rounded hover:opacity-80 transition"}><Trash2*/}
                {/*                        size={16}/> Delete</NavLink>*/}
                {/*                </div>*/}
                {/*            </td>*/}
                {/*        </tr>*/}
                {/*        <tr className={"border-t"}>*/}
                {/*            <td className={"px-6 py-4"}>Tomorrow at 10:00 am</td>*/}
                {/*            <td className={"px-6 py-2"}>*/}
                {/*                <div className={"flex gap-2"}>*/}
                {/*                    <NavLink to={""}*/}
                {/*                             className={"bg-backgroundGrey px-2.5 py-1 flex items-center gap-1 rounded hover:opacity-80 transition"}><Trash2*/}
                {/*                        size={16}/> Delete</NavLink>*/}
                {/*                </div>*/}
                {/*            </td>*/}
                {/*        </tr>*/}
                {/*        <tr className={"border-t"}>*/}
                {/*            <td className={"px-6 py-4"}>Tomorrow at 14:00 am</td>*/}
                {/*            <td className={"px-6 py-2"}>*/}
                {/*                <div className={"flex gap-2"}>*/}
                {/*                    <NavLink to={""}*/}
                {/*                             className={"bg-backgroundGrey px-2.5 py-1 flex items-center gap-1 rounded hover:opacity-80 transition"}><Trash2*/}
                {/*                        size={16}/> Delete</NavLink>*/}
                {/*                </div>*/}
                {/*            </td>*/}
                {/*        </tr>*/}
                {/*        </tbody>*/}
                {/*    </table>*/}
                {/*</div>*/}

            </div>
        </>
    )
}

export default AddEvents