import Header from "../components/Header";
import {AlertCircle, Delete, Download, Edit2, Edit3, ExternalLink, Plus, Trash, Trash2} from "react-feather";
import {NavLink, useParams} from "react-router-dom";
import InputWrapper from "../../components/InputWrapper";
import Form from "../components/Form";
import Empty from "../components/Empty";
import {useEffect, useState} from "react";
import Data from "../../Data";
import moment from "moment";
import EventBookings from "../components/EventBookings";
import Meta from "../../../components/Meta";

const Event = () => {
    const { id } = useParams();

    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        try {
            const event = await Data.fetchEventById({ id })
            setEvent(event.data)
        } catch(error){
            window.location.href = '/admin/upcoming-events'
        } finally {
            setLoading(false)
        }
    }, []);

    if(!event) return null

    return (
        <>
            <Meta title={`${event.Experience.name} on ${moment(event.date).format("MMM Do YYYY, H:mm A")}`} />
            <Header heading={`${event.Experience.name} on ${moment(event.date).format("MMM Do YYYY, H:mm A")}`} backToLabel={"Upcoming Events"} backToTo={"/admin/upcoming-events"} />
            <div className={"p-3 lg:p-6 grid grid-cols-1 gap-3 lg:gap-6 w-full overflow-x-scroll"}>

                <div className={"bg-white w-full rounded-lg border drop-shadow-sm [&>*]:py-4 [&>*]:px-6"}>
                    <div className={"my-2 flex justify-between items-center"}>
                        <p className={"text-lg"}>Event Details</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Status</p>
                        <p className={"text-textGrey col-span-2"}>{event.ticketsRemaining > 0 ? `${event.ticketsRemaining} tickets remaining` : 'Sold Out'}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Tickets Sold</p>
                        <p className={"text-textGrey col-span-2"}>{event.totalTickets === 0 ? '-' : `${event.totalTickets} ticket${event.totalTickets > 1 ? 's' : ''} (${event.bookings.length} booking${event.bookings.length > 1 ? 's' : ''})`}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Experience</p>
                        <NavLink to={`/admin/experiences/${event.Experience.id}`}
                                 className={"text-textGrey col-span-2 underline flex items-center gap-1"}>{event.Experience.name}
                            <ExternalLink size={16}/></NavLink>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Date &amp; Time</p>
                        <p className={"text-textGrey col-span-2"}>{moment(event.date).format("MMM Do YYYY, H:mm A")}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Duration</p>
                        <p className={"text-textGrey col-span-2"}>{event.Experience.duration} minutes</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Location</p>
                        <p className={"text-textGrey col-span-2"}>{event.Experience.location}</p>
                    </div>
                </div>

                {/*<div className={"bg-white w-full rounded-lg border drop-shadow-sm [&>*]:py-4 [&>*]:px-6"}>*/}
                {/*    <div className={"my-2"}>*/}
                {/*        <p className={"text-lg"}>Manifest</p>*/}
                {/*        <p className={"text-textGrey"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
                {/*            eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>*/}
                {/*    </div>*/}

                {/*    <div className={"mt-2 flex gap-2 border-t"}>*/}
                {/*        <NavLink to={""}*/}
                {/*                 className={"bg-backgroundGrey px-2.5 py-1 flex items-center gap-1 rounded hover:opacity-80 transition"}><Download*/}
                {/*            size={16}/> Download Manifest</NavLink>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <EventBookings/>
            </div>
        </>
    )
}

export default Event