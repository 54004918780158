import Header from "../components/Header";
import {Delete, Download, Edit2, Edit3, Plus, Trash, Trash2} from "react-feather";
import {NavLink} from "react-router-dom";
import InputWrapper from "../../components/InputWrapper";
import Form from "../components/Form";
import {useForm} from "react-hook-form";
import {useState} from "react";
import Repository from "../../../../repository";
import Data from "../../Data";
import Error from '../components/Error'
import Meta from "../../../components/Meta";

const Create = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm()

    const submit = async (data) => {
        setLoading(true)

        try {
            const result = await Data.createExperience(data)
            window.location.href = '/admin/experiences/' + result.data._id;

            setLoading(false)
            setError(false)
        } catch(error) {
            setLoading(false)
            setError(true)
        }
    }

    return (
        <>
            <Meta title={`Create Experience`} />
            <Header heading={"Create Experience"} backToLabel={"Experiences"} backToTo={"/admin/experiences"} />
            <div className={"p-3 lg:p-6 grid grid-cols-1 gap-3 lg:gap-6 w-full overflow-x-scroll"}>

                <Form onSubmit={handleSubmit(submit)}>

                    <InputWrapper label={"Name"}
                                  input={<input type={"text"} {...register('name', {required: true})} />}/>

                    <div className={"mt-2 flex gap-2"}>
                        <button type={"submit"} disabled={loading}
                                className={"bg-backgroundGrey border px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>{loading ? '...' : 'Continue'}</button>
                    </div>

                    {error ? <Error message={"Unable to create experience, please try again."}/> : null}
                </Form>
            </div>
        </>
    )
}

export default Create